.section-vcardbody {
  width: 99%;
  max-width: calc(100vw - 20px);
  padding: 20px;
  overflow-y: auto;
  background: url('../../../../public/img/bg-card2.png') repeat-x right top #f4f4f4;
  border-top: 4px solid #4A9FDE;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  border-radius: 0px;
  box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.46);
}

@media (min-width:992px) {
  .section-vcardbody {
    position: absolute;
    left: 30%;
    height: 570px;
    width: 40%;
    padding: 20px 45px;
  }
}

@media (max-width:992px) {
  .section-vcardbody {
    margin: 10px;
  }
}

.section-vcardbody-pgactive {
  left: 15%;
}

@media (min-width:992px) {
  .section-page-single {
    left: 55%;
    transform: translateY(-200px);
    visibility: hidden;
    padding: 20px;
    padding-top: 0px;
  }
}

@media (min-width:992px) {
  .section-page {
    z-index: 11;
    opacity: 0;
  }
}

.section-page-active {
  opacity: 1;
  z-index: 22;
  left: 55%;
  visibility: visible;
  transform: translateY(0px);
}

@media (min-width:992px) {
  .section-page-active {
    margin-left: 10px;
  }
}

@media (min-width: 1400px) {
  .section-page-active {
    margin-top: 0;
  }
}

@media (min-width:992px) {
  .page-mask {
    position: absolute;
    top: 605px;
    left: 30%;
    display: block;
    width: 40%;
    height: 20px;
    background: #333;
    z-index: 77;
    opacity: 0;
    transition: all 0.6s ease-in-out;
  }
}

.page-mask-active {
  left: 55%;
  opacity: 1;
  margin-left: 10px;
}

.ps-scrollbar-y-rail {
  opacity: 0.9 !important;
  right: 4px!important;
}

.section-margin-bottom {
  margin-bottom: 40px;
}