* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  background: #f7f7f7;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
}

ul,
ol {
  list-style: none;
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

li {
  margin-left: 15px;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 2px;
}

p {
  margin: 0 0 10px;
}

a {
  outline: none;
  text-decoration: none;
  color: #555;
}

a:hover,
a:focus {
  outline: none;
  text-decoration: none;
  color: #333;
}

main {
  width: 80%;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 60px;
  font-size: 16px;
  color: #878a8f;
}

main h1,
main h2,
main strong {
  color: #333;
}
