.app-item {
  position: relative;
  width: 100%;
  padding: 0;
  margin-bottom: 10px;
  opacity: 1;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  overflow: hidden;
}

.app-item a {
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.app-thumbnail {
  position: relative;
  display: block;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: 200px;
  text-align: center;
  color: #FFF;
}

.app-thumbnail:hover {
  color: #FFF;
}

.app-thumbnail:hover .app-info-wrapper {
  opacity: 1;
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
}

.app-thumbnail:hover .app-title,
.app-thumbnail:hover span.see-more {
  transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
}

.app-info-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  color: #FFF;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.app-info {
  position: absolute;
  top: 50%;
  margin-top: -55px;
  width: 100%;
}

.app-title {
  text-transform: uppercase;
  font-size: 20px;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.app-title:after {
  content: "";
  display: block;
  height: 1px;
  border-bottom: 1px solid #FFF;
  width: 50%;
  margin: 0 auto;
  opacity: 0.5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.app-description {
  padding: 0 10px;
  font-style: italic;
  letter-spacing: 3px;
  font-size: 12px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
}