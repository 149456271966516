.vcard-profile-pic {
    position: relative;
    width: 200px;
    height: 204px;
    margin: 0 auto;
    margin-bottom: 20px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow:  0 17.5px 30px -10px rgba(0,0,0,0.4);
    -moz-box-shadow:  0 17.5px 30px -10px rgba(0,0,0,0.4);
    box-shadow:  0 17.5px 30px -10px rgba(0,0,0,0.4);
}

.vcard-profile-pic img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;    
    border: none;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    transition: all 300ms linear;
    opacity: 1;
}