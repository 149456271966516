.btn-cv {
  position: fixed !important;
  bottom: 10px !important;
  right: 10px !important;
  height: 50px !important;
  width: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0 solid;
  border-radius: 50% !important;
  background: #0A5287 !important;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 0 5px #333;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 99;
}
