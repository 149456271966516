* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

body {
    background: #F7F7F7;
}

.notfound__container {
    font-family: 'Varela Round', sans-serif;
    color: #9B9B9B;
    position: relative;
    height: 100vh;
    text-align: center;
    font-size: 16px;
}
.notfound__container h1 {
    font-size: 32px;
    margin-top: 32px;
}

.notfound__container .boo-wrapper {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding-top: 64px;
    padding-bottom: 64px;
}

.notfound__container .boo {
    width: 160px;
    height: 184px;
    margin-left: auto;
    margin-right: auto;
    border: 3.3939393939px solid #9B9B9B;
    border-bottom: 0;
    overflow: hidden;
    border-radius: 80px 80px 0 0;
    -webkit-box-shadow: -16px 0 0 2px rgba(234, 234, 234, 0.5) inset;
            box-shadow: -16px 0 0 2px rgba(234, 234, 234, 0.5) inset;
    position: relative;
    padding-bottom: 32px;
    -webkit-animation: floating 3s ease-in-out infinite;
            animation: floating 3s ease-in-out infinite;
}

.notfound__container .boo::after {
    content: '';
    display: block;
    position: absolute;
    left: -18.8235294118px;
    bottom: -8.3116883117px;
    width: calc(100% + 32px);
    height: 32px;
    background-repeat: repeat-x;
    background-size: 32px 32px;
    background-position: left bottom;
    background-image:   linear-gradient(-45deg, #F7F7F7 16px, transparent 0), 
                        linear-gradient(45deg, #F7F7F7 16px, transparent 0), 
                        linear-gradient(-45deg, #9B9B9B 18.8235294118px, transparent 0), 
                        linear-gradient(45deg, #9B9B9B 18.8235294118px, transparent 0);
}

.notfound__container .boo .face {
    width: 24px;
    height: 3.2px;
    border-radius: 5px;
    background-color: #9B9B9B;
    position: absolute;
    left: 50%;
    bottom: 56px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.notfound__container .boo .face::before, 
.notfound__container .boo .face::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #9B9B9B;
    border-radius: 50%;
    position: absolute;
    bottom: 40px;
}

.notfound__container .boo .face::before {
    left: -24px;
}

.notfound__container .boo .face::after {
    right: -24px;
}

.notfound__container .shadow {
    width: 128px;
    height: 16px;
    background-color: rgba(234, 234, 234, 0.75);
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50%;
    -webkit-animation: floatingShadow 3s ease-in-out infinite;
            animation: floatingShadow 3s ease-in-out infinite;
}

.notfound__container .text,
.notfound__container .text h1 {
    margin: 15px auto;
} 

@-webkit-keyframes floating {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    45% {
        -webkit-transform: translate3d(0, -10%, 0);
                transform: translate3d(0, -10%, 0);
    }
    55% {
        -webkit-transform: translate3d(0, -10%, 0);
                transform: translate3d(0, -10%, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@keyframes floating {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    45% {
        -webkit-transform: translate3d(0, -10%, 0);
                transform: translate3d(0, -10%, 0);
    }
    55% {
        -webkit-transform: translate3d(0, -10%, 0);
                transform: translate3d(0, -10%, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@-webkit-keyframes floatingShadow {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    45% {
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
    }
    55% {
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes floatingShadow {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    45% {
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
    }
    55% {
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}