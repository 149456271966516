.section-home {
  z-index: 88;
  opacity: 1;
  overflow-y: auto;
}

.vcard-profile-description {
  text-align: center;
}

.profile-title {
  text-align: center;
  font-weight: bold;
  margin-top: 0;
  font-size: 34px;
  letter-spacing: -2px;
  margin-bottom: 8px;
  color: #4A9FDE;
}

.profile-subtitle {
  text-align: center;
  margin-top: 0;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.vcard-profile-description-text {
  padding: 10px;
  padding-top: 10px;
}

.vcard-profile-description-text p {
  font-size: 13px;
}

.hr1 {
  border: none;
  height: 1px;
  width: 100%;
  background: url('../../../public/img/hr1.png') no-repeat center center;
  margin: 0;
}

.vcard-profile-description-ft-item {
  margin-bottom: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  padding: 10px;
  background: url('../../../public/img/bg-btn.png') no-repeat center center #0A5287;
}

.vcard-profile-description-ft-item p,
.vcard-profile-description-ft-item p a {
  margin-bottom: 0;
  color: #FFF;
  font-size: 12px;
}

.section-title {
  color: #4A9FDE;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  margin-top: 0;
  border-bottom: 1px solid #4A9FDE;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.section-subtitle {
  font-weight: bold;
  color: #0A5287;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 4px solid #0A5287;
}

.resume-item {
  padding: 15px 10px;
  border: 4px double #ccc;
  margin-bottom: 15px;
}

.section-item-title-1, 
.section-item-title-2 {
  font-weight: bold;
  color: #333 !important;
  padding-left: 0;
  margin-left: 0;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  margin-bottom: 8px;
  letter-spacing: -1px;
}

.section-item-title-1 a,
.section-item-title-2 a {
  color: #333 !important;
}

.section-item-title-2 {
  padding-bottom: 5px;
  font-size: 15px;
}

.graduation-time,
.job, 
.language {
  line-height: 15px;
  font-style: italic;
  color: #555;
  font-size: 10px;
  margin-top: 5px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.graduation-description {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.graduation-description > * {
  flex: 1;
}

.resume-item p,
.graduation-description p,
.job-description p,
.language-description p {
  font-size: 12px;
  text-align: justify;
}

.graduation-description img {
  max-height: 60px !important;
  max-width: 60px !important;
  margin-right: 5px;
}

.graduation-description .certification {
  font-size: 80%;
}

.resume-buttons {
  margin-bottom: 30px;
}

.header-page-buttons {
  margin-top: -20px;
}

span.see-more {
  font-style: italic;
  text-transform: lowercase;
  letter-spacing: 3px;
  font-size: 12px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  transform: translateY(10px);
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
}

.contact-subtitle-1 {
  color: #0A5287;
  font-size: 14px;
  letter-spacing: -1px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 20px;
  padding-bottom: 5px;
}

.contact-subtitle-1:first-child {
  margin-top: 0;
}

.contact-infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-infos > * {
  flex: 1;
}

.contact-infos p {
  font-size: 13px;
  line-height: 16px;
}

.contact-result {
  display: none;
  justify-content: center;
  align-content: center;
  padding: 10px;
  border-radius: 1.5px;
  color: #FFF;
  font-size: 12px;
  text-align: center;
}

.contact-result.success {
  display: flex;
  background: #0A5287;
}

.contact-result.error {
  display: flex;
  background: #F95959;
}

.validator-error {
  margin: 5px 10px;
  font-size: 12px;
}

.form-group {
  margin-bottom: 8px;
}

.form-send {
  width: 100%;
}

.form-send:hover {
  background: #4A9FDE !important;
}

.form-control {
  display: block;
  height: 40px;
  width: 100%;
  padding: 6px 12px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

textarea.form-control {
  height: auto;
}

::-webkit-input-placeholder {
  font-style: italic;
  color: #999999 !important;
}

:-moz-placeholder {
  font-style: italic;
  color: #999999 !important;
}

::-moz-placeholder {
  font-style: italic;
  color: #999999 !important;
}

:-ms-input-placeholder {
  font-style: italic;
  color: #999999 !important;
}

.modal-wrap {
  display: none;
}

.modal-bg {
  background: #333;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.modal-content {
  background: #fafafa;
  font-size: 18px;
  position: fixed;
  top: 40%;
  left: 50%;
  margin-left: -125px;
  width: 250px;
  z-index: 9999;
  padding: 10px 20px;
}

.modal-content .modal-title {
  color: #4A9FDE;
  font-size: 22px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-content p {
  font-size: 14px;
  margin-bottom: 0;
}

#contactError .modal-title {
  color: #fff;
}
