:root {
  --background-color:       #E3E3E3;
  --main-color:             #006276;
  --main-color-dark:        #023844;
  --main-background:        #006276;
  --main-background-dark:   #023844;
  --secondary-color:        #F6F6F6;
  --title-primary-color:    #464646;
  --title-secondary-color:  #33A7FF;
  --text-primary-color:     #464646;
  --bar-text-color:         #464646;
  --bar-color:              #313131;
  --bar-background-color:   #CCD5DB;
  --menu-color:             #777777;
  --menu-action-color:      #33A7FF;
  --menu-border-color:      #999999;
  --timeline-title:         #33A7FF;
  --timeline-marker:        #33A7FF;
  --timeline-marker-dark:   #006276;
  --timeline-line:          #CCD5DB;
  --timeline-year:          #006276;
  --footer-color:           #777777;
  --footer-heart:           #F95959;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html{
  font-family:sans-serif;
  -ms-text-size-adjust:100%;
  -webkit-text-size-adjust:100%;
  font-size:62.5%;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

body {
  background: var(--background-color);
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: #333;
}

ul, ol {
  list-style: none;
  margin-top:0;
  margin-bottom:10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom:0;
}

li {
  margin-left: 15px;
}

textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 50px;
}

iframe {
  width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding :0;
}

input {
  line-height: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height :auto;
}

img {
  max-width: 100% !important;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}

select {
  background: #fff !important;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family:inherit;
  font-weight:500;
  line-height:1.1;
  color:inherit;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1,
.h1 {
  font-size: 36px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 14px;
}

h6,
.h6 {
  font-size: 2px;
}

p {
  margin: 0 0 10px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-muted {
  color:#999;
}

a {
  outline: none;
  text-decoration: none;
  color: #555;
}

a:hover, 
a:focus {
  outline: none;
  text-decoration: none;
  color: #333;
}

.btn {
  font-weight: bold;
  color: #FFF;
  background: #0A5287;
  border-radius: 0;
  font-size: 12px;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 2px;
}

.btn:hover {
  background: #000000;
  color: #FFF;
}

.btn-default {
  display: inline-block;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #0A5287 !important;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 0px solid transparent!important;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-default:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: #4A9FDE;
  z-index: -1;
}

.btn-default:hover {
  color: #FFF;
  border: 0px solid transparent!important;
}

.btn-default:hover:before {
  width: 100%;
}

.btn-default3 {
  background: #d9212a;
  color: #FFF;
  border: 0;
  text-transform: uppercase;
}

.btn-default3:hover {
  background: #009d9b;
  color: #FFF;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-default2 {
  font-size: 9px;
  background: #4A9FDE;
  font-weight: lighter;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.color1 {
  color: #0A5287;
}

.color2 {
  color: #4A9FDE;
}

.color3 {
  color: #d9212a;
}

.font1 {
  font-family: 'Montserrat', sans-serif;
}

.bold {
  font-weight: bold;
}

.box-shadow {
  -webkit-box-shadow: 0px 2px 6px 0px rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 0px 2px 6px 0px rgba(50, 50, 50, 0.3);
  box-shadow: 0px 2px 6px 0px rgba(50, 50, 50, 0.3);
}

.responsive-image {
  width: 100%;
}

.section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 10px;
}

.container {
  content: " ";
  clear: both;
  position: relative;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
    min-height: 570px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
