.menu {
  width: 15%;
  position: absolute;
  top: 0;
  left: 15%;
  padding-right: 10px;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  display: none;
}

@media (min-width:992px) {
  .menu {
    display: block;
  }
}

.menu-pgactive {
  left: 0%;
}
.menu-list {
  margin: 0;
  padding: 0;
}
.menu-list li {
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
  list-style: none;
}
.menu-list a {
  display: block;
  background: #0A5287;
  padding: 10px;
  color: #FFF !important;
  text-transform: uppercase;
  font-size: 12px;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.menu-list a:active,
.menu-list a:visited {
  text-decoration: none;
}
.menu-list a:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  background: #4A9FDE;
  z-index: -1;
}
.menu-list a:hover {
  color: #FFF !important;
  border: 0px solid transparent!important;
  text-decoration: none;
  padding-left: 20px;
}
.menu-list a:hover:before {
  width: 100%;
}
.menu-list a.menu-active {
  text-decoration: none;
  background: #4A9FDE;
  padding-left: 20px;
}