.social-icons {
  font-size: 28px;
  text-align: right;
  display: none;
}

@media (min-width: 768px) {
  .social-icons {
    display: block;
  }
}

.social-icons a {
  color: #FFF;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.social-icons a:hover {
  opacity: 0.7;
}

.footer-social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 50px;
}

.footer-social-icons i {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.footer-social-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  background: #333333;
  color: #FFF;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.footer-social-icons a:hover {
  color: #FFF;
  background: #0A5287;
}

